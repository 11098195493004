import { Link } from 'react-router-dom';

const linkClassName = 'transition hover:text-secondary'

export default function Footer(props) {
    return (
        <footer className={'bg-primary w-full flex flex-col lg:flex-row items-center lg:items-start justify-between py-8 px-8 text-white text-sm lg:text-xs xl:text-sm uppercase leading-normal lg:leading-none ' + (props.className || '')}>

            <List>
                <Item to='impressum' content='Impresszum' />
                <Item href='https://proart.hu/' content='ProArt Szövetség a Szerzői Jogokért' />
            </List>

            <div className='block lg:hidden bg-secondary h-1 w-[120px] my-4' />

            <List>
                <Item content={new Date().getFullYear() + ' © ProArt - Kézikönyv a szerzői jog érvényesítéséhez'} />
                <Item content={<>Fejlesztette:&nbsp;<a className={linkClassName} href='https://creo.hu/' target='_blank' rel='noreferrer'> Creo Group</a></>} />
            </List>

        </footer>
    );
}

function List(props) {
    return (
        <div className='flex flex-col lg:flex-row gap-4 lg:gap-0 items-center lg:items-start lg:divide-x-2 divide-primary-light'>
            {props.children}
        </div>
    );
}

function Item(props) {
    return (
        <div className='text-center flex divide-x-2 divide-primary-light lg:px-2 first:pl-0 last:pr-0'>
            {props.to && <Link className={linkClassName} to={props.to}>{props.content}</Link>}
            {props.href && <a className={linkClassName} href={props.href}>{props.content}</a>}
            {(!props.to && !props.href) && props.content}
        </div>
    );
}
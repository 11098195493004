import szjt from 'data/szjt.json';
import kjkt from 'data/kjkt.json';

export const getTitleByData = (type, data) => {
    let title = '';
    const arr = getDataArray(data);
    title += (type === 'SZJT' ? 'Szjt' : 'Kjkt') + '. ' + arr[0][0] + '. §'
    let bk = ''
    for (let i = 0; i < arr.length; i++)
        if (arr[i][1])
            bk += (bk.length ? ' - ' : '') + '(' + arr[i][1] + ')'
    title += ' ' + bk;
    return title;
};

export const getContentByData = (type, data) => {
    const json = (type === 'SZJT' ? szjt : kjkt)
    let content = '';

    const arr = getDataArray(data);

    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];

        let mainItem = json.children[item[0]];

        if (item.length === 1)
            content = extractChildren(item[0], mainItem, content);
        else
            content = extractChildren(item[0], mainItem, content, item[1]);
    }

    return content;
}

const extractChildren = (parentId, item, content, condition) => {
    let _content = content || '';
    if (!condition && item.text)
        _content += (_content.length ? '<br/><br/>' : '') + '(' + parentId + '). ' + item.text
    for (let j in item.children) {
        if (!condition || (j === condition)) {
            _content += (_content.length ? '<br/><br/>' : '') + '(' + j + '). ' + item.children[j].text
            if (item.children[j].children)
                extractChildren(item.children[j], _content);
        }
    }
    return _content;
}

const getDataArray = (data) => {
    const elems = data.split(',')
    const array = [];
    for (let i = 0; i < elems.length; i++)
        array.push(elems[i].split('.'))
    return array;
};

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { ImBook, ImBooks, ImSearch } from 'react-icons/im';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

import ModalChapterContent from 'elements/modal/ModalChapterContent';
import ModalDownload from 'elements/modal/ModalDownload';
import ModalSearch from 'elements/modal/ModalSearch';

import { getChapterByIndex, getNextChapterIndex, getPrevChapterIndex } from 'utils/BookUtils';
import { getIndexesFromPath } from 'utils/GenericUtils';

export default function Header(props) {
    const [chaperContentModalVisible, setChaperContentModalVisible] = useState(null);
    const [downloadModalVisible, setDownloadModalVisible] = useState(null);
    const [searchModalVisible, setSearchModalVisible] = useState(null);

    const [chapterIndex, setChapterIndex] = useState(null);
    const [sectionIndex, setSectionIndex] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');

    const { pathname } = useLocation();

    useEffect(() => {
        const indexes = getIndexesFromPath(pathname);
        setChapterIndex(indexes.chapter);
        setSectionIndex(indexes.section);
    }, [pathname]);

    const buttonClassName = 'text-white flex items-center justify-center whitespace-nowrap text-sm font-light px-3 py-2 gap-3 transition min-h-[36px] disabled:pointer-none disabled:opacity-50'
    const buttonDisabledClassName = ' pointer-events-none bg-primary opacity-50'

    return (
        <>

            <header className={'w-full flex flex-col ' + (props.className || '')}>

                <div className='bg-primary text-white text-center p-6 sm:p-8 gap-6'>

                    <Link to='/' className='flex flex-col items-center gap-4'>

                        <div className='h-2 w-[120px] bg-secondary' />
                        <h1 className='text-3xl sm:text-4xl xl:text-5xl font-cursive tracking-wide my-2'>KÉZIKÖNYV A SZERZŐI JOG ÉRVÉNYESÍTÉSÉHEZ</h1>
                        <div className='flex flex-col sm:flex-row gap-3 sm:gap-6 items-center'>
                            <div className='h-[3px] w-[80px] md:w-[150px] max-w-[1160px] bg-primary-light' />
                            <h4 className='text-base xl:text-2xl tracking-wider font-thin'>Útmutató a gyakorlat számára</h4>
                            <div className='hidden sm:block h-[3px] w-[80px] md:w-[150px] max-w-[1160px] bg-primary-light' />
                        </div>

                    </Link>

                </div>

                <Sticky className='w-full sticky-header' innerClass='bg-primary-light mt-4 w-full grid grid-cols-1 md:grid-cols-3 gap-3 p-3 transition' innerActiveClass='bg-primary-light/90 backdrop-blur-sm shadow-xl'>

                    <div className='h-full flex items-center'>

                        <div className='flex flex-row w-full md:w-[197px]'>
                            <input className='w-full self-stretch outline-none px-3 text-sm bg-white/30 text-white placeholder:text-white/80' placeholder='Keresés ...' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyDown={(e) => { e.key === 'Enter' && setSearchModalVisible(true) }} />
                            <button className={buttonClassName + ' bg-primary hover:bg-secondary px-0'} onClick={() => setSearchModalVisible(true)}><ImSearch /></button>
                        </div>

                    </div>

                    <div className={' items-center justify-center ' + ((!chapterIndex && !sectionIndex) ? 'hidden md:flex' : 'flex')}>

                        {(chapterIndex && sectionIndex) && <button onClick={() => setChaperContentModalVisible(true)} className={buttonClassName + ' bg-secondary hover:bg-primary w-full md:w-auto'}><ImBooks />Fejezettartalom</button>}

                        {(chapterIndex && !sectionIndex) && <div className='w-full flex justify-center gap-3'>

                            <Link to={'chapter/' + getPrevChapterIndex(chapterIndex)} className={buttonClassName + ' flex-1 md:flex-none ' + (getPrevChapterIndex(chapterIndex) ? ' bg-secondary hover:bg-primary' : buttonDisabledClassName)}>Fejezet<FaChevronLeft /></Link>
                            <Link to={'chapter/' + getNextChapterIndex(chapterIndex)} className={buttonClassName + ' flex-1 md:flex-none ' + (getNextChapterIndex(chapterIndex) ? ' bg-secondary hover:bg-primary' : buttonDisabledClassName)}><FaChevronRight />Fejezet</Link>

                        </div>}

                    </div>

                    <div className='flex items-center justify-end'>
                        <button className={buttonClassName + ' bg-primary hover:bg-secondary w-full md:w-auto'} onClick={() => setDownloadModalVisible(true)}><ImBook />Teljes könyv letöltése</button>
                    </div>

                </Sticky>

            </header>

            {chaperContentModalVisible && <ModalChapterContent title={getChapterByIndex(chapterIndex).title} onClose={() => setChaperContentModalVisible(false)} />}
            {downloadModalVisible && <ModalDownload onClose={() => setDownloadModalVisible(false)} />}
            {searchModalVisible && <ModalSearch searchTerm={searchTerm} onSearchTermChanged={(term) => setSearchTerm(term)} onClose={() => setSearchModalVisible(false)} />}

        </>
    );
}
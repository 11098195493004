import IllustratedPageContent from 'elements/IllustratedPageContent';

export default function Impressum() {
    return (
        <div className='w-full flex-1 flex flex-col items-center justify-center'>

            <IllustratedPageContent contentClassName='grid grid-cols-1 md:grid-cols-2 gap-8 pl-4'>

                <Block title='Szerkesztő' list={['Dr. Grad-Gyenge Anikó']} />
                <Block title='Felelős kiadó' list={['Dr. Horváth Péter']} />
                <Block className='md:col-span-2' contentClassName='grid sm:grid-cols-2 md:grid-cols-3' title='Szerzők' list={['Dr. Békés Gergely', 'Dr. Bérczes László', 'Dr. Financsek Zsuzsanna', 'Dr. Gondol Daniella', 'Dr. Grad-Gyenge Anikó', 'Dr. Horváth Katalin', 'Dr. Jókúti András', 'Dr. Kabai Eszter', 'Dr. Lábody Péter', 'Dr. Lukácsi Péter', 'Dr. Molnár Alexandra', 'Dr. Szesztai-Szép Eszter']} />
                <Block className='md:col-span-2' contentClassName='grid sm:grid-cols-2 md:grid-cols-3' title='Szakmailag lektorálta' list={['Dr. Faludi Gábor', 'Dr. Horváth Péter', 'Dr. Kelemen Sándor']} />

            </IllustratedPageContent>

        </div>
    );
}

function Block(props) {
    return (
        <div className={'w-full flex flex-col text-white items-start ' + (props.className || '')}>

            <div className='px-6 sm:px-8 py-3 sm:py-4 font-cursive text-2xl sm:text-3xl whitespace-nowrap -mx-4 bg-secondary z-10'>{props.title}</div>

            <div className={'w-full gap-4 bg-primary p-8 text-white -mt-4 pt-12 ' + (props.contentClassName || '')}>
                {props.list.map((item, index) => (<div key={index} className='font-light'>{item}</div>))}
            </div>

        </div>
    );
}


import { BrowserRouter as Router, Routes, Route, useLocation, Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';

import Header from 'components/Header';
import Footer from 'components/Footer';

import StartPage from 'pages/StartPage';
import Impressum from 'pages/Impressum.js';
import SectionListPage from 'pages/SectionListPage';
import Paragraph from 'pages/ParagraphPage';

import ScrollToTop from 'utils/ScrollToTop';

export default function App() {
  return (
    <Router>

      <div className='relative w-full min-h-screen flex flex-col items-center bg-neutral-50 shadow-xl z-10 site-px site-pt'>

        <Header className='z-50' />

        <div className='w-full flex-1 flex flex-col'>

          <Routes>
            <Route element={<AnimationLayout />}>
              <Route path='*' element={<StartPage />} />
              <Route path='/chapter/:chapterIndex' element={<SectionListPage />} />
              <Route path='/chapter/:chapterIndex/section/:sectionIndex' element={<Paragraph />} />
              <Route path='/impressum' element={<Impressum />} />
            </Route>
          </Routes>

          <ScrollToTop />

        </div>

      </div>

      <Footer className='w-full sticky top-0 bottom-0 left-0 right-0' />

    </Router>
  );
}

const AnimationLayout = () => {
  const pageVariants = { initial: { opacity: .5 }, in: { opacity: 1 }, out: { opacity: 0 } };
  const pageTransition = { ease: 'easeOut', duration: .05 };

  const { pathname } = useLocation();

  return (
    <motion.div className='w-full flex-1 flex site-content-py' key={pathname} initial='initial' animate='in' variants={pageVariants} transition={pageTransition}>
      <Outlet />
    </motion.div>
  );
};
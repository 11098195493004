import { useState } from 'react';
import { useParams } from 'react-router-dom';

import ModalDialog from 'elements/modal/ModalDialog';

import book from 'data/book.json';

import { getFootnoteById, footnoteRegExp, processParagraph } from 'utils/BookUtils';
import { getTitleByData, getContentByData } from 'utils/AnchorUtils';

// const footnoteRegExp = /\[footnoteRef:([0-9]+)\]/g;
// const kjktRegExp = /\[kjkt:([^\]]+)\]([^\]]+)\[\/kjkt\]/g;
// const szjtRegExp = /\[szjt:([^\]]+)\]([^\]]+)\[\/szjt\]/g;

export default function ParagraphPage() {
    let { chapterIndex, sectionIndex } = useParams();

    const getFootnoteList = () => {
        let text = '';

        const data = book.chapters[chapterIndex - 1].sections[sectionIndex - 1].subsections;

        for (let i = 0; i < data.length; i++) {
            if (data[i].type === 'paragraph') {
                text += ' ' + data[i].text;
            } else {
                for (let j = 0; j < data[i].paragraphs.length; j++)
                    if (data[i].paragraphs[j].type === 'paragraph')
                        text += ' ' + data[i].paragraphs[j].text;
            }
        }

        const array = [...text.matchAll(footnoteRegExp)];
        let idList = [];
        for (let i = 0; i < array.length; i++)
            idList.push(parseInt(array[i][1]))
        let uniqIdList = [...new Set(idList)];

        return uniqIdList;
    };

    return (
        <div className='w-full flex justify-center'>

            <div className='w-full max-w-boxed flex'>

                <div className='flex flex-col flex-1'>

                    <h2 className='heading-padding-y'>{book.chapters[chapterIndex - 1].sections[sectionIndex - 1].title}</h2>

                    {book.chapters[chapterIndex - 1].sections[sectionIndex - 1].subsections.map((item, index) => (item.type === 'paragraph' ? <Paragraph key={index} paragraph={item} /> : <Subsection key={index} list={item} subindex={index} />))}

                    {(getFootnoteList() && getFootnoteList().length > 0) && <div className='flex flex-col gap-4 pt-6 sm:pt-8 mt-3 sm:mt-5 border-t'>
                        {getFootnoteList().map((item) => (<Footnote key={item} id={item} />))}
                    </div>}

                </div>

            </div>

        </div>
    );
}

function Footnote(props) {
    return (<div className='text-xs sm:text-sm sm:leading-loose text-justify'><span className=''>[{props.id}]</span> {getFootnoteById(props.id).text}</div>);
}

function Subsection(props) {
    return (
        <div className='' id={props.subindex + 1}>
            <h3 className='heading-padding-y'>{props.list.title}</h3>
            {props.list.paragraphs.map((item, index) => (<Paragraph key={index} paragraph={item} />))}
        </div>
    );
}

function Paragraph(props) {
    const [modalVisible, setModalVisible] = useState(false);

    const [modalTitle, setModalTitle] = useState(false);
    const [modalContent, setModalContent] = useState(false);

    // const processParagraph = (p) => {
    //     let process = p;

    //     process = replaceFootnote(process)
    //     process = replaceKjkt(process)
    //     process = replaceSzjt(process)

    //     return process;
    // }

    // const replaceFootnote = (p) => { return p.replace(footnoteRegExp, '<b data-footnote=$1 style="cursor:pointer;"><sup>[$1]</sup></b>'); }
    // const replaceKjkt = (p) => { return p.replace(kjktRegExp, '<b data-kjkt=$1 style="cursor:pointer;">$2</b>'); }
    // const replaceSzjt = (p) => { return p.replace(szjtRegExp, '<b data-szjt=$1 style="cursor:pointer;">$2</b>'); }

    const handleClick = (e) => {
        const el = e.target.closest("B");
        if (el && e.currentTarget.contains(el)) {
            if (el.getAttribute('data-footnote'))
                showFootnote(parseInt(el.getAttribute('data-footnote')))
            else if (el.getAttribute('data-kjkt'))
                showData('KJKT', el.getAttribute('data-kjkt'));
            else if (el.getAttribute('data-szjt'))
                showData('SZJT', el.getAttribute('data-szjt'));
        }
    };

    const showFootnote = (id) => {
        if (getFootnoteById(id)) {
            setModalTitle(id + '. lábjegyzet');
            setModalContent(getFootnoteById(id).text);
            setModalVisible(true);
        }
        console.log('FOOTNOTE hivatkozás: ' + id);
    };

    const showData = (type, data) => {
        if (getContentByData(type, data)) {
            setModalTitle(getTitleByData(type, data));
            setModalContent(getContentByData(type, data));
            setModalVisible(true);
        }
        console.log(type + ' hivatkozás: ' + data);
    };

    return (
        <>
            <div className='paragraph-padding-y text-justify text-sm sm:text-base leading-relaxed sm:leading-loose' onClick={(e) => handleClick(e)} dangerouslySetInnerHTML={{ __html: processParagraph(props.paragraph.text) }} />
            {modalVisible && <ModalDialog title={modalTitle} content={modalContent} onClose={() => setModalVisible(false)} />}
        </>
    );
}
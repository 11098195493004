import { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { ImArrowLeft2 } from 'react-icons/im';
import { arabToRoman } from 'roman-numbers';
import { ImBooks } from 'react-icons/im';

import Modal, { ModalButton, ModalHeader, ModalContent } from 'elements/modal/Modal';

import { getIndexesFromPath, scrollWithOffset } from 'utils/GenericUtils';

import book from 'data/book.json';

export default function ModalChapterContent(props) {
    const [chapterIndex, setChapterIndex] = useState(null);
    // const [sectionIndex, setSectionIndex] = useState(null);

    const { pathname } = useLocation();

    useEffect(() => {
        const indexes = getIndexesFromPath(pathname);
        setChapterIndex(indexes.chapter);
        // setSectionIndex(indexes.section);
    }, [pathname]);

    return (
        <Modal className='max-w-lg' {...props}>

            <ModalHeader className='text-center'>
                <div className='text-xl opacity-70 text-[.9em]'>{arabToRoman(parseInt(chapterIndex))}. fejezet</div>
                {props.title}
            </ModalHeader>

            <ModalContent scroll className='pr-[8px] gap-2' disableVerticalPadding>

                {chapterIndex && book.chapters[chapterIndex - 1].sections.map((item, index) => (
                    <div key={index} className='flex flex-col bg-primary-light/10 hover:bg-primary transition group'>
                        <Item title={item.title} index={index} chapterIndex={chapterIndex} onClose={props.onClose} />
                        {item.subsections && <div className='flex flex-col'>
                            {item.subsections.map((subsection, subindex) => (subsection.type === 'subsection' ? <Item key={subindex} index={index} chapterIndex={chapterIndex} subindex={subindex + 1} title={subsection.title} onClose={props.onClose} /> : null))}
                        </div>}
                    </div>
                ))}

            </ModalContent>

            <ModalContent disableVerticalPadding fixed className='gap-2'>

                <ModalButton label='Fejezetek' secondary to='/' onClick={() => props.onClose()} icon={<ImBooks />} />
                <ModalButton label='Vissza' onClick={() => props.onClose()} icon={<ImArrowLeft2 />} />

            </ModalContent>

        </Modal>
    );
}

function Item(props) {
    return (
        <HashLink
            className={'text-left transition px-3 group-hover:text-white hover:opacity-80 ' + (props.subindex ? 'text-[13px] py-1 first:pt-0 last:pb-3' : 'text-[14px] font-bold text-primary py-3')}
            to={'/chapter/' + props.chapterIndex + '/section/' + (props.index + 1) + (props.subindex ? ('#' + props.subindex) : '#')}
            scroll={el => scrollWithOffset(el)}
            smooth
            onClick={() => props.onClose()}>

            {props.title}

        </HashLink>
    );
}

export default function IllustratedPageContent(props) {
    return (
        <div className={'w-full max-w-boxed flex flex-col lg:flex-row gap-4 sm:gap-16 xl:gap-24 items-center py-4  ' + (props.className || '')}>

            <div className={'w-full flex-1 gap-12 md:gap-20 mb-8 p-4 xl:p-0 ' + (props.contentClassName || '')}>
                {props.children}
            </div>

            <div className='w-[75%] md:w-[45%] lg:w-[25%] aspect-[409/555] bg-no-repeat bg-cover bg-center mb-12 lg:mb-0' style={{ backgroundImage: 'url(./assets/images/tree.png)' }}></div>

        </div>
    );
}
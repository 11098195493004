import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { arabToRoman } from 'roman-numbers';

import book from 'data/book.json';

export default function SectionListPage() {
    let { chapterIndex } = useParams();

    return (
        <div className='w-full flex justify-center'>

            <div className='w-full max-w-boxed flex flex-col gap-16 lg:gap-24 pb-4 pt-4 mb-12 lg:mb-0'>

                <div className='flex flex-col items-center gap-4'>
                    <div className='text-center text-xl opacity-70'>{arabToRoman(parseInt(chapterIndex))}. fejezet</div>
                    <div className='h-2 w-[120px] bg-primary-light' />
                    <h2 className='text-center text-4xl lg:text-5xl'>{book.chapters[chapterIndex - 1].title}</h2>
                </div>

                <div className='grid lg:grid-cols-2 gap-16 lg:gap-20'>
                    {book.chapters[chapterIndex - 1].sections.map((section, index) => (<SectionBox key={index} section={section} index={index} chapterIndex={chapterIndex} />))}
                </div>

            </div>

        </div>
    );
}

function SectionBox(props) {
    const [hover, setHover] = useState(false);

    const blockClassName = 'h-[76px] sm:h-[96px] aspect-square flex items-center justify-center text-xl sm:text-2xl font-cursive'

    return (
        <Link to={'/chapter/' + props.chapterIndex + '/section/' + (props.index + 1)} className='relative flex items-center text-white' onMouseEnter={e => setHover(true)} onMouseLeave={e => setHover(false)}>

            <motion.div
                className='flex items-start justify-center z-20'
                initial={hover ? 'hover' : 'normal'}
                animate={hover ? 'hover' : 'normal'}
                variants={{ hover: { height: '100%', y: 0 }, normal: { height: 'auto', y: 0 } }}>

                <motion.div
                    className={blockClassName + ' bg-secondary'}
                    initial={hover ? 'hover' : 'normal'}
                    animate={hover ? 'hover' : 'normal'}
                    variants={{ hover: { y: -32 }, normal: { y: 0 } }}>
                    {props.section.title.slice(0, props.section.title.indexOf(' '))}
                </motion.div>

            </motion.div>

            <motion.div
                className='min-h-full w-full bg-primary box-content px-6 py-4 -ml-4 mr-4 z-10 flex items-center'
                style={{ backfaceVisibility: 'hidden' }}
                initial={hover ? 'hover' : 'normal'}
                animate={hover ? 'hover' : 'normal'}
                variants={{ hover: {}, normal: {} }}>

                <div className='w-full pl-4 font-light text-sm sm:text-base xl:text-lg'>
                    {props.section.title.slice(props.section.title.indexOf(' ') + 1)}
                </div>

            </motion.div>

            <motion.div
                className='absolute right-0 bottom-0 flex items-center justify-center'
                initial={hover ? 'hover' : 'normal'}
                animate={hover ? 'hover' : 'normal'}
                variants={{ hover: { height: 'auto', y: 0 }, normal: { height: '100%', y: 0 } }}>

                <motion.div
                    className={blockClassName + ' bg-primary-light text-white/0'}
                    initial={hover ? 'hover' : 'normal'}
                    animate={hover ? 'hover' : 'normal'}
                    variants={{ hover: { y: 32 }, normal: { y: 0 } }}>
                    {props.section.title.slice(0, props.section.title.indexOf(' '))}
                </motion.div>

            </motion.div>

        </Link>
    );
}
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { TfiClose, TfiAlert, TfiCheck, TfiMinus } from 'react-icons/tfi';

export default function Modal(props) {
    return (
        <ModalBackground onBackgroundClick={() => props.onClose()}>

            <motion.div
                className={'w-full flex flex-col max-h-screen overflow-hidden px-8 py-12 ' + (props.className || '')}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}>

                <div className='flex flex-col flex-1 overflow-hidden bg-neutral-50 shadow-xl p-2 gap-2'>
                    {props.children}
                </div>

            </motion.div>

        </ModalBackground>
    );
}

export function ModalBackground(props) {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='fixed top-0 left-0 z-[1000] w-full h-full'>
            <div className='relative w-full h-full flex items-center justify-center'>
                <div className='absolute top-0 left-0 w-full h-full bg-black/50 z-0' onClick={() => props.onBackgroundClick()} />
                <div className='z-10'>
                    {props.children}
                </div>
            </div>
        </motion.div>
    );
}

export function ModalHeader(props) {
    return (
        <div className='w-full px-4 sm:px-6 py-4 bg-primary font-alternative text-white text-lg sm:text-xl leading-tight sm:leading-snug font-cursive'>
            {props.children}
        </div>
    );
}

export function ModalContent(props) {
    return (
        <div className={'flex-1 ' + (props.fixed ? '' : (props.scroll ? 'overflow-y-scroll modal-scrollbar ' : 'overflow-y-auto ')) + (props.disableVerticalPadding ? '' : 'py-2')}>
            <div className={'w-full h-full flex flex-col ' + (props.className || '')}>
                {props.children}
            </div>
        </div>
    );
}

export function ModalButton(props) {
    const baseClassName = 'flex flex-row gap-2 items-center justify-center disabled:opacity-50 disabled:pointer-events-none transition p-4 text-white text-sm font-semibold disabled:cursor-not-allowed ' + (props.className || '')
    const primaryClassName = 'bg-secondary hover:bg-primary'
    const secondaryClassName = 'bg-primary hover:bg-secondary'
    const className = baseClassName + ' ' + (props.secondary ? secondaryClassName : primaryClassName)

    if (props.to)
        return (<Link className={className} to={props.to} onClick={() => props.onClick()} disabled={props.disabled}><ModalButtonContent {...props} /></Link>)
    else
        return (<button className={className} onClick={() => props.onClick()} disabled={props.disabled}><ModalButtonContent {...props} /></button>);
}

function ModalButtonContent(props) {
    return (
        <>
            {props.icon && props.icon}
            {props.label}
        </>
    );
};

export function ModalInput(props) {
    return (
        <div className={'flex flex-col ' + (props.className || '')}>
            <input {...props} className={'text-sm outline-none bg-white/30 p-3 text-center ' + (!props.error ? 'placeholder:text-white/80 text-white font-light' : 'text-white font-bold placeholder:text-white placeholder:font-bold')} placeholder={props.label} />
            {props.error && <ModalFeedback data={{ type: 'ERROR', message: props.error }} />}
        </div>
    );
};

export function ModalFeedback(props) {
    // const errorClassName = 'bg-red-800';
    const errorClassName = 'bg-primary';
    const warningClassName = 'bg-yellow-800';
    const successClassName = 'bg-green-800';

    const getTypeClassName = () => {
        if (props.data.type === 'WARN')
            return warningClassName;
        else if (props.data.type === 'SUCCESS')
            return successClassName;
        else
            return errorClassName;
    };

    const getIcon = () => {
        if (props.data.type === 'WARN')
            return <TfiMinus />;
        else if (props.data.type === 'SUCCESS')
            return <TfiCheck />;
        else
            return <TfiAlert />;
    };

    return (
        <div className={'text-white shadow-xl py-2 px-3 flex flex-row items-center justify-center gap-2 ' + getTypeClassName() + ' ' + (props.className || '')}>

            {(props.data && props.data.message) && <div className='tracking-wide text-opacity-90 text-xs'>{props.data.message}</div>}

            {/* {props.data && getIcon()} */}

        </div>
    );
};

import { ImBook, ImArrowLeft2 } from 'react-icons/im';

import Modal, { ModalButton, ModalHeader, ModalContent } from 'elements/modal/Modal';

export default function ModalDialog(props) {
    return (
        <Modal className='max-w-lg' {...props}>

            <ModalHeader>{props.title}</ModalHeader>

            <ModalContent>

                <div className='text-sm px-2' dangerouslySetInnerHTML={{ __html: props.content }} />

            </ModalContent>

            <ModalContent className='gap-2' disableVerticalPadding fixed>

                <ModalButton label='Vissza' onClick={() => props.onClose()} icon={<ImArrowLeft2 />} />

            </ModalContent>

        </Modal>
    );
}
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ImBook, ImArrowLeft2 } from 'react-icons/im';

import Modal, { ModalButton, ModalHeader, ModalContent, ModalInput } from 'elements/modal/Modal';

import { isEmail } from 'utils/GenericUtils';

export default function ModalDownload(props) {
    const [feedback, setFeedback] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrganization] = useState('');

    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [organizationError, setOrganizationError] = useState(null);

    useEffect(() => {
        setFeedback(false);
        setButtonDisabled(false);
    }, []);

    useEffect(() => { setNameError(null); }, [name]);
    useEffect(() => { setEmailError(null); }, [email]);
    useEffect(() => { setOrganizationError(null); }, [organization]);

    const validate = () => {
        let valid = true;

        if (!name || name === '') { setNameError('Kérjük, adja meg teljes nevét!'); valid = false; }
        else { setNameError(null); }

        if (!email || email === '') { setEmailError('Kérjük, adja meg e-mail címét!'); valid = false; }
        else if (!isEmail(email)) { setEmailError('Kérjük, ellenőrizze a megadott címet!'); valid = false; }
        else { setNameError(null); }

        return valid;
    };

    const send = () => {
        setButtonDisabled(true);

        axios.get('/szerzoijogikezikonyv-backings/backings/handbook/download/', {
            params: { name: name, email: email, organization: organization }
        }).then(function (response) {
            setFeedback(true);
        }).catch(function (error) {
            alert('Sajnos hiba történt a folyamat során, kérjük, kis idő elteltével próbálkozzon újra. Köszönjük!');
        }).finally(function () {
            setButtonDisabled(false);
        });
    }

    return (
        <Modal className='max-w-lg' {...props}>

            <ModalHeader className='text-center'>
                <div className='text-xl opacity-70 text-[.9em]'>Teljes könyv letöltése</div>
                Kézikönyv a szerzői jog érvényesítéséhez
            </ModalHeader>

            <ModalContent scroll disableVerticalPadding className='gap-2 pr-[8px]'>

                <div className='flex flex-col gap-2 text-sm p-2'>
                    <div className='font-bold text-primary'>Oldalunkon ingyenesen letöltheti a Kézikönyv a szerzői jog érvényesítéséhez című kézikönyv Önnek dedikált példányát.</div>
                    <div>Kérjük, adja meg a letöltéshez szükséges adatait. A ProArt  a kötet frissítéséről a megadott mailcímre tájékoztatást küld. Amennyiben a letöltés során bármilyen problémája keletkezik, kérjük, a <a className='anchor' href='mailto:kezikonyv@proart.hu'>kezikonyv@proart.hu</a> mailcímen jelezze. Köszönjük!</div>
                </div>

            </ModalContent>

            <ModalContent className='gap-2' disableVerticalPadding fixed>

                <div className={'flex flex-col gap-3 text-white text-sm font-light transition ' + (feedback ? 'bg-primary p-4' : 'bg-primary-light p-2')}>

                    {feedback ? <>

                        <div className='font-bold'>Köszönjük letöltését!</div>
                        <div>A Kézikönyv a szerzői jog érvényesítéséhez című kötet Ön számára dedikált példányát a megadott e-mail címre továbbítottuk.</div>

                    </> : <>

                        <div className='flex flex-col gap-2'>
                            <ModalInput label='Teljes név *' value={name} onChange={(e) => setName(e.target.value)} error={nameError} />
                            <ModalInput label='E-mail cím *' value={email} onChange={(e) => setEmail(e.target.value)} error={emailError} />
                            <ModalInput label='Szervezet' value={organization} onChange={(e) => setOrganization(e.target.value)} error={organizationError} />
                        </div>

                        <ModalButton label='Teljes könyv letöltése' disabled={buttonDisabled} secondary onClick={() => validate() && send()} icon={<ImBook />} />

                        <div className='text-white/80 text-xs text-center mb-0.5'>A "Teljes könyv letöltése" gomb megnyomásával hozzájárulok, hogy adataimat a ProArt a kézikönyv továbbfejlesztésénél használja.</div>

                    </>}

                </div>

            </ModalContent>

            <ModalContent className='gap-2' disableVerticalPadding fixed>

                <ModalButton label='Vissza' onClick={() => props.onClose()} icon={<ImArrowLeft2 />} />

            </ModalContent>

        </Modal>
    );
}
import { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';
import { ImArrowLeft2, ImSearch } from 'react-icons/im';
import { arabToRoman } from 'roman-numbers';

import Modal, { ModalButton, ModalHeader, ModalContent, ModalInput } from 'elements/modal/Modal';

import { getChapterByIndex, getSectionByIndex, processParagraph } from 'utils/BookUtils';
import { scrollWithOffset } from 'utils/GenericUtils';

export default function ModalSearch(props) {
    const [resultList, setResultList] = useState([]);

    const search = () => {
        axios.post('https://szerzoijogikezikonyv.hu/szjk-portal/methods/bookControl/query', {
            str: props.searchTerm
        }).then(function (response) {
            setResultList(response.data || []);
        }).catch(function (error) {
            alert('Sajnos hiba történt a folyamat során, kérjük, kis idő elteltével próbálkozzon újra. Köszönjük!');
        }).finally(function () {
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { search(); }, [props.searchTerm]);

    return (
        <Modal className='max-w-lg' {...props}>

            <ModalHeader className='text-center'>
                <div className='text-xl opacity-70 text-[.9em]'>Keresett kifejezés</div>
                {props.searchTerm || 'Nincs megadva'}
            </ModalHeader>

            <ModalContent className='gap-2' disableVerticalPadding fixed>

                <div className='relative bg-primary-light p-2'>
                    <input className='w-full self-stretch outline-none p-3 pr-10 text-sm bg-white/30 text-white placeholder:text-white/80' placeholder='Keresés ...' value={props.searchTerm} onChange={(e) => props.onSearchTermChanged(e.target.value)} />
                    <div className='absolute top-0 right-0 h-full aspect-square flex items-center justify-center text-white'><ImSearch /></div>
                </div>

            </ModalContent>

            {resultList.length > 0 && <ModalContent className='gap-2 pr-[8px]' disableVerticalPadding scroll>

                <div className='flex flex-col gap-2'>{resultList.map((item, index) => (<Item key={index} data={item} onClose={props.onClose} />))}</div>

            </ModalContent>}

            {resultList.length === 0 && <ModalContent className='gap-2 ' disableVerticalPadding>

                <div className='bg-primary p-4 text-white text-sm font-light'>
                    {props.searchTerm && <>Sajnáljuk, a megadott <span className='font-bold'>"{props.searchTerm}"</span> keresési kifejezésre nincs elérhető találat. Kérjük, próbálkozzon eltérő keresési kifejezéssel.</>}
                    {!props.searchTerm && <>Kérjük, a tartalomban való kereséshez adjon meg legalább egy, vagy akár több keresési kifejezést.</>}
                </div>

            </ModalContent>}

            <ModalContent className='gap-2' disableVerticalPadding fixed>

                <ModalButton label='Vissza' onClick={() => props.onClose()} icon={<ImArrowLeft2 />} />

            </ModalContent>

        </Modal>
    );
}

function Item(props) {
    return (
        <HashLink
            className='flex flex-col p-3 gap-3 bg-primary-light/10 hover:bg-primary transition group'
            to={'/chapter/' + props.data.chapterId + '/section/' + props.data.sectionId + (props.data.subSectionId ? ('#' + props.data.subSectionId) : '#')}
            scroll={el => scrollWithOffset(el)}
            onClick={() => props.onClose()}>

            <div className='flex flex-col gap-1'>
                <div className='text-[14px] font-bold text-primary group-hover:text-white group-hover:opacity-80 transition'>{arabToRoman(props.data.chapterId) + '. ' + getChapterByIndex(props.data.chapterId).title}</div>
                <div className='text-[13px] group-hover:text-white group-hover:opacity-80 transition'>{getSectionByIndex(props.data.chapterId, props.data.sectionId).title}</div>
            </div>

            <div className='text-xs group-hover:text-white hover:opacity-80 transition' dangerouslySetInnerHTML={{ __html: processParagraph(props.data.text) }} />

        </HashLink>
    );
}
import book from 'data/book.json';

export const footnoteRegExp = /\[footnoteRef:([0-9]+)\]/g;
export const kjktRegExp = /\[kjkt:([^\]]+)\]([^\]]+)\[\/kjkt\]/g;
export const szjtRegExp = /\[szjt:([^\]]+)\]([^\]]+)\[\/szjt\]/g;

export const getChapterByIndex = (index) => {
    return book.chapters[parseInt(index - 1)];
};

export const getSectionByIndex = (chapterIndex, sectionIndex) => {
    const chapter = getChapterByIndex(chapterIndex);
    return chapter.sections[parseInt(sectionIndex - 1)];
};

export const getNextChapterIndex = (current) => {
    if (book.chapters[parseInt(current)]) return current + 1;
    else return null;
};

export const getPrevChapterIndex = (current) => {
    if (book.chapters[parseInt(current - 2)]) return current - 1;
    else return null;
};

export const getFootnoteById = (id) => {
    if (isNaN(id))
        id = parseInt(id);
    for (let i = 0; i < book.indexes.length; i++)
        if (book.indexes[i].id === id)
            return book.indexes[i];
    return null;
};

export const processParagraph = (p) => {
    let process = p;

    process = replaceFootnote(process)
    process = replaceKjkt(process)
    process = replaceSzjt(process)

    return process;
};

export const replaceFootnote = (p) => { return p.replace(footnoteRegExp, '<b data-footnote=$1 style="cursor:pointer;"><sup>[$1]</sup></b>'); }
export const replaceKjkt = (p) => { return p.replace(kjktRegExp, '<b data-kjkt=$1 style="cursor:pointer;">$2</b>'); }
export const replaceSzjt = (p) => { return p.replace(szjtRegExp, '<b data-szjt=$1 style="cursor:pointer;">$2</b>'); }
import { matchPath } from 'react-router';

export const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const getIndexesFromPath = (pathname) => {
    let obj = { chapter: null, section: null }

    const chapterMatch = matchPath({ path: '/chapter/:chapterIndex' }, pathname);
    const sectionMatch = matchPath({ path: '/chapter/:chapterIndex/section/:sectionIndex' }, pathname);

    if (chapterMatch && chapterMatch.params && chapterMatch.params.chapterIndex) {
        obj.chapter = parseInt(chapterMatch.params.chapterIndex);
    } else if (sectionMatch && sectionMatch.params && sectionMatch.params.chapterIndex && sectionMatch.params.sectionIndex) {
        obj.chapter = parseInt(sectionMatch.params.chapterIndex);
        obj.section = parseInt(sectionMatch.params.sectionIndex);
    }

    // console.log(obj);

    return obj;
};

export const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -(document.getElementsByClassName('sticky-header')[0] ? document.getElementsByClassName('sticky-header')[0].clientHeight : 0) - 30;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { arabToRoman } from 'roman-numbers';

import IllustratedPageContent from 'elements/IllustratedPageContent';

import book from 'data/book.json';

export default function StartPage() {
    const chapters = book.chapters;
    const order = '. fejezet';

    return (
        <div className='w-full flex-1 flex flex-col items-center justify-center'>

            <IllustratedPageContent contentClassName='grid grid-cols-1 md:grid-cols-2'>
                {chapters.map((chapters, index) => (<Button key={index} title={chapters.title} label={arabToRoman(index + 1) + order} index={index} />))}
            </IllustratedPageContent>

        </div>
    );
}

function Button(props) {
    const [hover, setHover] = useState(false);

    const blockClassName = 'px-6 sm:px-8 py-3 sm:py-4 font-cursive text-2xl sm:text-3xl whitespace-nowrap -mx-4'

    return (
        <Link to={'/chapter/' + (props.index + 1)} className='relative flex flex-col items-start text-white' onMouseEnter={e => setHover(true)} onMouseLeave={e => setHover(false)}>

            <motion.div
                className='flex items-center justify-center'
                initial={hover ? 'hover' : 'normal'}
                animate={hover ? 'hover' : 'normal'}
                variants={{ hover: { width: '100%', y: 0 }, normal: { width: 'auto', y: 0 } }}>

                <div className={blockClassName + ' bg-secondary z-20'}>{props.label}</div>

            </motion.div>

            <motion.div
                className='w-full bg-primary -mt-4 pt-8 sm:pt-10 p-6 sm:p-8 text-base xl:text-xl z-10 font-light text-center'
                style={{ backfaceVisibility: 'hidden' }}
                initial={hover ? 'hover' : 'normal'}
                animate={hover ? 'hover' : 'normal'}
                variants={{ hover: { scale: 1 }, normal: { scale: 1 } }}>{props.title}</motion.div>

            <motion.div
                className='absolute right-0 -bottom-4 flex items-end justify-center'
                initial={hover ? 'hover' : 'normal'}
                animate={hover ? 'hover' : 'normal'}
                variants={{ hover: { width: '100%', y: 0 }, normal: { width: 'auto', y: 0 } }}>

                <div className={blockClassName + ' bg-primary-light text-white/0 select-none'}>{props.label}</div>

            </motion.div>

        </Link>
    );
}